<template>
  <div class="row">
    <div class="col-lg-12">
      <el-alert :closable="false" class="mb-3 col-8" title="💡 Info" type="success">
        <b>Auto Reminder</b> is a configuration that will send message to your customer if they don't reply after your Customer Service last response. For an example, you want to remind your customer to reply you message because it's related to their booking confirmation.<br/>
        - You can set your own time configuration and message that will be send to your customer.<br/>
        - You also can mark the conversation as Resolved if your customer don't respond after several hours<br/>
        - For customer convenience, you can only set auto reminder by <b>hour time unit</b>
      </el-alert>
    </div>
    <div class="col-lg-3 mb-2" v-for="(item, i) in autoReminderList" :key="i">
      <div class="card">
        <div class="card-header">
          Reminder After: {{ item.reminder_after }} hour(s)
        </div>
        <div class="card-body" style="height: 150px; overflow-y: auto">
          {{ item.message }}
        </div>
        <div class="card-footer d-flex flex-row-reverse">
          <b-button v-if="checkPermission.delete" @click="confirmRemoveAutoReminder(i)" type="button" variant="danger" class="ml-2">
            <font-awesome icon="trash"/>
          </b-button>
          <b-button v-if="checkPermission.update" @click="handlerModalEdit(i); v$.form.$touch();" type="button" variant="primary" class="ml-2">
            <font-awesome icon="pencil-alt"/>
          </b-button>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div v-if="checkPermission.create" @click="showModalAdd(); v$.form.$touch();" class="card" style="height: 100%;">
        <div class="card-body items-center text-center" style="cursor: pointer; display:table;">
          <span style="display:table-cell; vertical-align:middle;"><font-awesome icon="plus" size="2x"/><br/>Add new Auto Reminder</span>
        </div>
      </div>
    </div>
    <b-modal v-model="showModalEdit" :title="modalTitle" hide-footer>
      <b-form @submit.prevent="updateData" @reset="closeModalEdit">
        <b-form-group
          id="user-group"
          :label="$t('auto_reminder.title.reminder_after')"
          label-for="edit-title">
          <b-input-group append="hour(s)">
            <b-form-input
              id="edit-title"
              v-model="v$.form.reminder_after.$model"
              type="number"
              :placeholder="$t('auto_reminder.placeholder.reminder_after')"
              required />
          </b-input-group>
          <b-form-invalid-feedback :state="!v$.form.reminder_after.$invalid" v-if="v$.form.reminder_after.$errors[0]">
            <span v-if="!['minValue'].includes(v$.form.reminder_after.$errors[0].$validator)">{{ $t('validation.' + v$.form.reminder_after.$errors[0].$validator) }}</span>
            <span v-else> {{ v$.form.reminder_after.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="user-group"
          :label="$t('auto_reminder.title.message')"
          label-for="edit-description">
          <b-form-textarea
            id="edit-description"
            v-model="v$.form.message.$model"
            rows="6"
            max-rows="6"
            :placeholder="$t('auto_reminder.placeholder.message')"
            required />
          <b-form-invalid-feedback :state="!v$.form.message.$invalid" v-if="v$.form.message.$errors[0]">
            {{ $t('validation.' + v$.form.message.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-if="modalType == 'add' || form.index == (this.autoReminderList.length - 1)"
          :label="$t('auto_reminder.title.do_closing')">
          <el-switch active-text="Yes" inactive-text="No" v-model="form.do_closing" active-color="#128c7f" />
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <el-button @click="modalClickedAction" :loading="loading.update_data" class="btn ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
          <b-button @click="closeModalEdit" type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import workspaces from '@/api/workspaces';
import popupErrorMessages from '@/library/popup-error-messages';
import useVuelidate from '@vuelidate/core';
import { required, numeric, minValue } from '@vuelidate/validators';

export default {
  name: 'AutoReminder',
  // metaInfo: {
  //   title: 'Auto Reminder',
  // },
  props: {
    workspace_id: {
      type: String,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      loading: {
        update_data: false,
      },
      isLoading: false,
      loaderStack: 0,
      loader: null,
      form: {},
      autoReminderList: [],
      showModalEdit: false,
      modalTitle: 'Edit Auto Reminder',
      modalType: 'edit',
      deletedIndex: 0,
      company_id: this.$store.state.company.activeCompany.id,
    };
  },
  mount() {
  },
  computed: {
    /* eslint-disable space-in-parens */
    minValueReminder() {
      if (this.autoReminderList.length > 0 ) {
        const reminder_length = this.autoReminderList.length;
        let flag = this.autoReminderList[reminder_length - 1];
        if (this.modalType === 'edit') {
          flag = this.autoReminderList[this.form.index - 1];
        }
        return parseInt(flag ? flag.reminder_after : 0, 0) + 1;
      }
      return 1;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  methods: {
    confirmRemoveAutoReminder(index) {
      this.$confirm(this.$t('auto_reminder.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.deletedIndex = index;
            this.modalType = 'delete';
            await this.updateData();
            instance.confirmButtonLoading = false;
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    modalClickedAction() {
      if (this.modalType === 'edit') {
        this.updateData();
      } else if (this.modalType === 'add') {
        this.updateData();
      }
    },
    showModalAdd() {
      this.showModalEdit = true;
      this.modalType = 'add';
      this.modalTitle = 'Add new Auto Reminder';
      this.form = {
        reminder_after: this.autoReminderList.length > 0 ? parseInt(this.autoReminderList[this.autoReminderList.length - 1].reminder_after, 0) + 1 : 1,
        message: '',
        message_type: 'text',
      };
    },
    handlerModalEdit(index) {
      this.form = cloneDeep(this.autoReminderList[index]);
      this.form.index = index;
      this.modalType = 'edit';
      this.modalTitle = 'Edit Auto Reminder';
      this.showModalEdit = true;
    },
    closeModalEdit() {
      this.showModalEdit = false;
      this.v$.form.$reset();
      this.form = {};
    },
    async loadData() {
      this.$parent.loading.setting = true;
      const response = await workspaces.auto_reminder({ id: this.workspace_id, company_id: this.company_id });
      this.$parent.loading.setting = false;
      await popupErrorMessages(response);
      this.autoReminderList = response.data.auto_reminder;
    },
    async updateData() {
      this.v$.form.$touch();
      if (this.v$.form.$error && this.modalType !== 'delete') {
        this.$message({
          type: 'warning',
          message: this.$t('validation.cant_submit'),
        });
        return;
      }
      let update_obj = cloneDeep(this.autoReminderList);
      if (this.modalType === 'add') {
        update_obj.push(this.form);
      } else if (this.modalType === 'delete') {
        update_obj[this.deletedIndex] = null;
      }
      this.loading.update_data = true;
      update_obj[this.form.index] = this.form;
      update_obj = update_obj.filter((v) => v);
      const response = await workspaces.update_auto_reminder({
        id: this.workspace_id,
        company_id: this.company_id,
        data: {
          auto_reminder: update_obj,
        },
      });
      this.loading.update_data = false;
      await popupErrorMessages(response).catch(() => {
        update_obj.pop();
      });
      this.showModalEdit = false;
      this.$message({
        type: 'success',
        message: this.$t('settings.success.edit'),
      });
      this.$nextTick(() => this.loadData());
    },
  },
  validations() {
    return {
      form: {
        reminder_after: {
          required,
          numeric,
          minValue: minValue(this.minValueReminder),
        },
        message: {
          required,
        },
      },
    };
  },
};
</script>
